import React, { useState, useLayoutEffect, useEffect } from 'react';
import { getBackendSrv } from 'app/core/services/backend_srv';
import { lastValueFrom } from 'rxjs';
import { Modal } from '@grafana/ui/src/components/Modal/Modal';
import { useLocalStorage } from 'react-use';
import { Button } from '@grafana/ui';

type endpoint = {
  name: string;
  url: string;
  headers: Object;
};

type license = {
  name: string;
  expired: boolean;
  remainingDays: number;
  remainingTimeStr: string;
};

async function getDatasourcesEndpoints(): Promise<endpoint[]> {
  let endpoints: endpoint[];
  endpoints = [];

  let datasources = await getBackendSrv().get(`/api/datasources`);
  datasources.filter((item: any) => {
    if (item.name.includes('Datasource') && item.jsonData.hasOwnProperty('apiURL')) {
      endpoints.push({
        name: item.name,
        url: item.jsonData.apiURL + `/api/v1/system/license`,
        headers: { Authorization: 'Bearer ' + item.jsonData.apiToken },
      });
    }
  });

  return endpoints;
}

function convertDuration(duration: number): string {
  // duration is in seconds
  const days = Math.floor(duration / (3600 * 24));
  duration -= days * 3600 * 24;
  const hours = Math.floor(duration / 3600);
  duration -= hours * 3600;
  const minutes = Math.floor(duration / 60);
  return `${days} dias ${hours} horas e ${minutes} minutos`;
}

async function licenseExpiredCheck() {
  let endpoints = await getDatasourcesEndpoints();

  let expiredLicenses = Promise.all(
    endpoints.map(async (datasource: any) => {
      let item = await lastValueFrom(getBackendSrv().fetch<any>({ url: datasource.url, headers: datasource.headers }));
      if (!item.hasOwnProperty('data') || !item.data.license || !item.data.license.is_valid) {
        return {
          name: datasource.name,
          expired: true,
          remainingDays: 0,
          remainingTimeStr: '0 dias 0 horas e 0 minutos',
        } as license;
      } else {
        let remainingDays = item.data.license.remaining_time / 864e11;
        let remainingTimeStr = convertDuration(item.data.license.remaining_time / 1e9);
        return {
          name: datasource.name,
          expired: false,
          remainingDays: remainingDays,
          remainingTimeStr: remainingTimeStr,
        } as license;
      }
    })
  );

  return expiredLicenses;
}

export const LicenseExpiry = () => {
  const [licenses, setLicenses] = useState<license[]>([]);
  const [notificationModalState, setNotificationModalState] = useState(false);
  const [qrModalState, setQrModalState] = useState(false);
  const [muteNotificationTimestamp, setMuteNotificationTimestamp] = useLocalStorage(
    `birmind-bwise-mute-notification`,
    0
  );

  useLayoutEffect(() => {
    if (Date.now() > (muteNotificationTimestamp ? muteNotificationTimestamp : 0) + 864e5) {
      licenseExpiredCheck().then((response) => {
        setLicenses(response);
      });
    }
  }, [setLicenses, muteNotificationTimestamp]);

  useEffect(() => {
    if (
      licenses.find((license) => {
        if (!license.expired && license.remainingDays < 30 && license.remainingDays > 0) {
          return true;
        }
        return false;
      }) &&
      window.location.pathname !== '/login'
    ) {
      setNotificationModalState(true);
    }
  }, [licenses, setNotificationModalState]);

  let expired = licenses.filter((item) => {
    if (item.expired) {
      return true;
    }
    return false;
  });

  let almostExpired = licenses.filter((item) => {
    if (!item.expired && item.remainingDays < 30 && item.remainingDays > 0) {
      return true;
    }
    return false;
  });

  return (
    <>
      {
        <>
          <Modal
            isOpen={notificationModalState}
            title="Notificação de Expiração de Licenças"
            onDismiss={() => {
              setNotificationModalState(false);
            }}
          >
            <div style={{ margin: '10px 20px' }}>
              <h4> As licenças para os seguintes módulos estão prestes a expirar: </h4>
              {almostExpired.map((item) => {
                return (
                  <ul key={item.name}>
                    <div style={{ padding: '15px 25px' }}>
                      <h5> {item.name} </h5>
                      <ul> Expira em {item.remainingTimeStr}. </ul>
                    </div>
                  </ul>
                );
              })}
            </div>
            {(expired.length > 0 ? true : null) && (
              <div style={{ margin: '10px 20px' }}>
                <h4> As licenças para os seguintes módulos não são mais válidas: </h4>
                {expired.map((item) => {
                  return (
                    <ul key={item.name}>
                      <div style={{ padding: '15px 25px' }}>
                        <h5> {item.name} </h5>
                      </div>
                    </ul>
                  );
                })}
              </div>
            )}
            <Modal.ButtonRow>
              <Button
                variant="secondary"
                onClick={() => {
                  setMuteNotificationTimestamp(Date.now());
                  setNotificationModalState(false);
                }}
                type="button"
              >
                Silenciar essa notificação por 24 horas
              </Button>
              <Button
                variant="primary"
                onClick={() => {
                  setNotificationModalState(false);
                  setQrModalState(true);
                }}
                type="button"
              >
                Entrar em contato
              </Button>
            </Modal.ButtonRow>
          </Modal>

          <Modal
            isOpen={qrModalState}
            title="Notificação de Expiração de Licenças"
            onDismiss={() => {
              setQrModalState(false);
            }}
          >
            <div>
              <h5>
                {' '}
                Para entrar em contato, envie um email para{' '}
                <a href="mailto: comercial@birmind.com.br" style={{ color: '#3366CC', textDecoration: 'underline' }}>
                  comercial@birmind.com.br
                </a>{' '}
                ou, se preferir, envie uma mensagem para nosso WhatsApp escaneando o QR Code a seguir:{' '}
              </h5>
            </div>
            <div style={{ margin: '20px 1px', display: 'flex', justifyContent: 'center' }}>
              <img style={{ justifyContent: 'center', height: '80%' }} src={`public/img/qrWhatsapp.jpg`} />
            </div>
            <Modal.ButtonRow>
              <Button
                variant="secondary"
                onClick={() => {
                  setQrModalState(false);
                  setNotificationModalState(true);
                }}
                type="button"
              >
                Voltar
              </Button>
            </Modal.ButtonRow>
          </Modal>
        </>
      }
    </>
  );
};
