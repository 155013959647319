import { css } from '@emotion/react';
import { GrafanaTheme2, ThemeTypographyVariant } from '@grafana/data';
import { getFocusStyles } from '../mixins';

export function getElementStyles(theme: GrafanaTheme2) {
  let rgbPrimaryColor = theme.isDark ? 255 : 0;
  let invertFilterPrimaryColor = theme.isDark ? '100%' : '0%';

  return css`
    html {
      -ms-overflow-style: scrollbar;
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
      height: 100%;
      font-size: ${theme.typography.htmlFontSize}px;
      font-family: ${theme.typography.fontFamily};
      line-height: ${theme.typography.body.lineHeight};
      font-kerning: normal;
    }

    body {
      height: 100%;
      width: 100%;
      position: absolute;
      color: ${theme.colors.text.primary};
      background-color: ${theme.colors.background.canvas};
      ${getVariantStyles(theme.typography.body)}
    }

    h1,
    .h1 {
      ${getVariantStyles(theme.typography.h1)}
    }
    h2,
    .h2 {
      ${getVariantStyles(theme.typography.h2)}
    }
    h3,
    .h3 {
      ${getVariantStyles(theme.typography.h3)}
    }
    h4,
    .h4 {
      ${getVariantStyles(theme.typography.h4)}
    }
    h5,
    .h5 {
      ${getVariantStyles(theme.typography.h5)}
    }
    h6,
    .h6 {
      ${getVariantStyles(theme.typography.h6)}
    }

    p {
      margin: 0 0 ${theme.spacing(2)};
    }

    button {
      letter-spacing: ${theme.typography.body.letterSpacing};

      &:focus-visible {
        outline: ${getFocusStyles(theme)};
      }
      &:focus {
        outline: none;
      }
    }

    // Ex: 14px base font * 85% = about 12px
    small {
      font-size: ${theme.typography.bodySmall.fontSize};
    }

    b,
    strong {
      font-weight: ${theme.typography.fontWeightMedium};
    }

    em {
      font-style: italic;
      color: ${theme.colors.text.primary};
    }

    cite {
      font-style: normal;
    }

    // Utility classes
    .muted {
      color: ${theme.colors.text.secondary};
    }

    a.muted:hover,
    a.muted:focus {
      color: ${theme.colors.text.primary};
    }

    .text-warning {
      color: ${theme.colors.warning.text};

      &:hover,
      &:focus {
        color: ${theme.colors.emphasize(theme.colors.warning.text, 0.15)};
      }
    }

    .text-error {
      color: ${theme.colors.error.text};

      &:hover,
      &:focus {
        color: ${theme.colors.emphasize(theme.colors.error.text, 0.15)};
      }
    }

    .text-success {
      color: $success-text-color;

      &:hover,
      &:focus {
        color: ${theme.colors.emphasize(theme.colors.success.text, 0.15)};
      }
    }

    a {
      cursor: pointer;
      color: ${theme.colors.text.primary};
      text-decoration: none;

      &:focus {
        outline: none;
      }

      &:focus-visible {
        ${getFocusStyles(theme)}
      }

      &: [disabled] {
        cursor: default;
        pointer-events: none !important;
      }
    }

    .text-link {
      text-decoration: none;
    }

    .text-left {
      text-align: left;
    }

    .text-right {
      text-align: right;
    }

    .text-center {
      text-align: center;
    }

    .flex {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: center;
      gap: 10%;
      margin-left: 5%;
    }

    .img-wrapper {
      border-radius: 10px;
      width: 25%;
      min-width: 180px;
      height: 250px;
      text-align: center;
      margin-top: 20px;
      padding-top: 10px;
      padding-bottom: 10px;
      border: solid ${theme.colors.text.primary} 1px;
      transition: 0.1s;
    }

    .img-wrapper:hover {
      background-color: rgba(${rgbPrimaryColor}, ${rgbPrimaryColor}, ${rgbPrimaryColor}, 0.1);
    }

    .img-wrapper img {
      max-height: 50%;
      margin: 0;
      padding: 0;
      filter: brightness(0%) saturate(100%) invert(${invertFilterPrimaryColor});
    }

    .img-wrapper p {
      margin-top: 10px;
      color: ${theme.colors.text.primary};
      text-decoration: none;
    }

    .appname {
      margin-top: 20px;
      margin-bottom: 0;
      color: white;
      font-size: 18px;
      font-weight: bold;
      text-decoration: none;
    }

    .description {
      text-align: center;
      margin: 10%;
      margin-top: 0;
      font-size: 13px;
      color: white;
      font-weight: lighter;
    }

    .img-wrapper p:hover {
      text-decoration: none;
    }

    .div-content {
      width: 100%;
      text-align: center;
      color: ${theme.colors.text.primary};
      font-size: 20px;
    }
  `;
}

export function getVariantStyles(variant: ThemeTypographyVariant) {
  return `
    margin: 0;
    font-size: ${variant.fontSize};    
    line-height: ${variant.lineHeight};
    font-weight: ${variant.fontWeight};
    letter-spacing: ${variant.letterSpacing};
    font-family: ${variant.fontFamily};
    margin-bottom: 0.45em;
  `;
}
