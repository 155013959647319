import React from 'react';
import { css } from '@emotion/css';
import { useStyles2 } from '@grafana/ui';
import { GrafanaTheme2 } from '@grafana/data';

export interface Props {
  children: JSX.Element;
  highlight?: String;
}

export const NavFeatureHighlight = ({ children, highlight }: Props): JSX.Element => {
  const styles = useStyles2(getStyles);
  if (highlight === 'notInstalled') {
    return (
      <div>
        {children}
        <span className={styles.notInstalled} />
      </div>
    );
  }
  if (highlight === 'noLicense') {
    return (
      <div>
        {children}
        <span className={styles.noLicense} />
      </div>
    );
  }
  return (
    <div>
      {children}
      <span className={styles.success} />
    </div>
  );
};

const getStyles = (theme: GrafanaTheme2) => {
  return {
    success: css`
      background-color: ${theme.colors.success.main};
      border-radius: 50%;
      width: 6px;
      height: 6px;
      display: inline-block;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    `,
    noLicense: css`
      background-color: ${theme.colors.error.main};
      border-radius: 50%;
      width: 6px;
      height: 6px;
      display: inline-block;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    `,
    notInstalled: css`
      background-color: transparent;
      border-radius: 50%;
      width: 6px;
      height: 6px;
      display: inline-block;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      &:before {
        content: url(public/img/locker.svg);
        position: absolute;
        width: 20px;
        top: -5px;
        left: 25;
        bottom: 0;
        right: 0;
        z-index: 100;
      }
    `,
  };
};
